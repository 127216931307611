.password-container {
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"] {
  width: 100%;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
}
.hide {
  position: absolute;
  top: 52%;
  right: 2%;
  cursor: pointer;
  width: 30px;
  height: 22px;
  color: lightgray;
}

.check {
  position: absolute;
  top: 52%;
  right: 8%;
  cursor: pointer;
  width: 30px;
  height: 22px;
  color: lightgray;
}

.register__success {
  margin-top: 20px;
  h4 {
    color: green;
  }
}

.select {
  height: 45px;
  span {
    padding: 5px 7px;
  }
  select {
    padding-top: 3px;
    padding-left: 7px;
    padding-right: 15px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
  }

  select::-ms-expand {
    display: none;
  }

  input {
    height: 32px;
  }
}

.phoneInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
