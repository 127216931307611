@use '../utils' as *;
/*----------------------------------------*/
/*  00. OFF CANVAS CSS START
/*----------------------------------------*/

// offcanvas area css
.offcanvas{
    &__area{
        position: fixed;
        right: -495px;
        top: 0;
        width: 465px;
        height: 100%;
        background: $white  none repeat scroll 0 0;
        overflow-y: scroll;
        @include box-shadow(-5px 0 20px -5px rgba(0, 0, 0, 0.5));
        -webkit-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        z-index: 999;

        @media #{$xs}{
            width: 290px;
        }

        &.opened{
            right: 0px;
        }
    }
    &__wrapper{
        position: relative;
        padding: 45px;
        @media #{$xs}{
           padding: 20px;
        }
    }
    &__close{
        position: absolute;
        top: 37px;
        right: 45px;
        @media #{$xs}{
            top: 10px;
            right: 20px;
        }
        &-btn{
            display: inline-block;
            font-size: 16px;
            height: 45px;
            width: 45px;
            line-height: 45px;
            background: $d-yellow;
            color: #fff;
            border-radius: 50%;
            &:hover{
                background: $black;
            }
        }
    }
    &__logo{
        padding-bottom: 20px;
        border-bottom: 1px solid $border;
    }
    &__search{
        position: relative;
        & input{
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding-right: 20px;
            background: transparent;
            border: none;
            outline: none;
            border-bottom: 1px solid $border;
            font-size: 14px;
            &::placeholder{
                color: $grey-2;
            }
            &:focus{
                border-color: $black;
            }
        }
        & button{
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            font-size: 14px;
            color: $grey-2;
        }
    }
}