// Solid Colors ---------------
$white: #ffffff;
$black: #222;;
$black-2: #2F3037;
$black-3: #1F2024;


// Grey Colors ---------------
$grey:#666;
$grey-2:#999;

//Light BG
$light-bg: #F3F5F6;
$light-bg-s: #F4F5F7;

// basic color
$d-blue: #263C97;
$d-blue-light: #0068c9;
$s-blue: #16bcdc;
$d-yellow: #fcbe00;
$d-orange: #e5ac00;
$d-red: #CC1414;
$t-green: #5aab19;


// Border Colors ---------------
$border:#e5e5e5;
$border-2:#E3E6EE;


// Heading Colors ---------------
$heading-color: #222222;

