.personal {
  padding: 30px 0;
  margin-bottom: 0px;

  &__buy {
    margin-left: 10px;
    font-size: 18px;
  }

  span {
    color: blue;
    cursor: pointer;
  }
}

.items {
  margin-bottom: 10px;
}

.select {
  height: 45px;

  span {
    padding: 5px 7px;
  }

  select {
    padding-top: 3px;
    padding-left: 7px;
    padding-right: 15px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
  }

  select::-ms-expand {
    display: none;
  }

  input {
    height: 32px;
  }
}

.phoneInput {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    background-color: white
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}