.category {
  margin: 20px 0;

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    gap: 20px;
  }

  &__img {
    width: 200px;
  }

  &__edit {
    display: flex;
    align-self: flex-start;
    gap: 10px;
  }

  @media only screen and (max-width: 600px) {
    &__edit {
      display: flex;
      flex-direction: column;
    }

    &__img {
      width: 100px;
      height: 100px;
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    cursor: pointer;

  }
}

.winner {
  &__photo {
    width: 350px;
    height: 500px;
    display: block;
    margin: auto;
  }

  &__photo-small {
    cursor: pointer;
    
  }
}