.history{
  padding: 30px 0;
  &__btn{
    display: flex;
    gap: 20px;
  }

  &__list{
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    img{
      height: 100px;
      width: 150px;
      object-fit: contain;
    }
  }

  &__list-stock{
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    img{
      height: 100px;
      width: 150px;
      object-fit: contain;
    }
  }

  &__qr{
    margin: 0 auto;;
  }

  &__active{
    padding: 20px 30px;
  }

  &__check-title{
    margin-left: 10px;
    margin-top: 5px;;
    font-weight: bold;
    color: black

  }
}

.big-checkbox {width: 30px; height: 30px;}

.winners{
  color: green;
}