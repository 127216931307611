.products {
  padding: 30px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &__item {}

  &__img {
    padding: 10px;

    display: block;
    margin: auto;
    height: 150px;
    width: 200px;
    object-fit: contain;
  }

  &__btn {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &__buy {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__limit {
    text-align: center;
    font-weight: 700;
    color: red;
  }

  &__participate {
    font-weight: 700;
    font-size: 16px;
    color: green;
  }
}



.products__item:hover .products__img {
  transform: scale(1.1);
  transition: 0.5s;
}

.heart {
  position: absolute;
  top: 3%;
  right: 4%;
  cursor: pointer;
  width: 30px;
  z-index: 10;
  height: 30px;
  color: lightgray;
}

.cart__icon {
  height: 50px;
  width: 50px;
}