.cart__btn{
    width: 300px;
    margin:auto;

    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.cart__cover{
    text-align: right;
}

.products__checked {
    border-color: red;
    border-width: 1px;
}

.products__all{
    margin-left: 10px;
    margin-top: 5px;;
    font-weight: bold;
    color: black
}