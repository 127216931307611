.products {
  padding: 30px 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;

  &__item {}

  &__img {
    padding: 10px;

    display: block;
    margin: auto;
    height: 150px;
    width: 200px;
    object-fit: contain;
  }

  &__btn {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 125px;
  }

  &__buy {
    display: flex;

    padding: 10px 0;
  }

  &__buy-group {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}

.products__item:hover {
  box-shadow: 0px 5px 30px 0px rgb(0 0 0 / 10%);
  background-color: #ffffff;
}


.products__item:hover .products__img {
  transform: scale(1.1);
  transition: 0.5s;
}

.heart {
  position: absolute;
  top: 3%;
  right: 4%;
  cursor: pointer;
  width: 30px;
  z-index: 10;
  height: 30px;
  color: lightgray;
}

.cart__icon {
  height: 50px;
  width: 50px;
}

.list-group {
  padding: 0 60px;
  display: flex;
  flex-direction: row;
}

.details-group {
  width: 110px;
}