.icon {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.icon:hover {
  color: rgb(223, 149, 13);

}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-description {
  font-size: 12px;
  white-space: nowrap;
  color: rgb(185, 185, 185);
}

.text-info {
  font-size: 13px;
  line-height: 1.1;
  display: block;
  white-space: nowrap;
  color: #ffffff;
}