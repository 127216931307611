.product-details{
    padding: 30px 0;
}

.active{
    display: flex !important;
    justify-content: center;
}

.details__img{
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.details__img-main{
    height: 400px;
    width: 400px;
}

.product__photo{
    border-radius: 15px;
    
}

.product__slide{
    position: relative;
}

.product__heart{
    position: absolute;
    top: 3%;
    right: 4%;
    cursor: pointer;
    width: 30px;
    z-index: 10;
    height: 30px;
    color: lightgray;
  }

  .product__details-content{
    position: relative;
  }

  .product-stocks{
    padding-bottom: 10px;
  }