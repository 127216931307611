.pagination{
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
    overflow-x: auto;
    &-wrapper {
        width: 100%;
        overflow: hidden;
    }
    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .08);
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--bs-pagination-color);
        border-radius: 50px;
    }
}

.page-link{
    cursor: pointer;
    &--long {
        margin-bottom: 10px;
    }
    &__active {
        color: #fff;
        background-color: var(--bs-pagination-color);
        border-color: transparent;
        border-width: 1px;
    }
}

.page-link__active{
    color: #fff!important;
    background-color: var(--bs-pagination-color)!important;
    border-color: transparent!important;
    border-width: 1px;
}