.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: gray;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}

.main{
  padding: 100px 0;
}

.help {
  display: flex;
  justify-content: center;
  gap: 95px;
  padding: 45px 10px;

  &-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    gap: 20px;
  }
}

.text {
  &-top {
    font-size: 14px;
    font-weight: bold;
  }

  &-bottom {
    font-size: 12px;
    color: gray;
  }
}

.product-block {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.categories {
  padding: 30px 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;

  &__item {
    width: 250px;
    height: 250px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__img{
    height: 150px;
    object-fit: contain
  }
  
}

.categories__item:hover {
  box-shadow: 0px 5px 30px 0px rgb(0 0 0 / 10%);
  background-color: #ffffff;
}


.categories__item:hover .categories__img{
  transform: scale(1.1);
  transition: 0.5s;
}

.banners{
  margin: 20px 0;
  height: 600px;
  border-radius: 30px;
}

@media (max-width: 800px) {
	.banners{
    height: 200px;
	}
}
.banners{
  height: 280px !important;
}

.stock{
  cursor: pointer;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  width: 370px;
  height: 800px;
}