.header {
  background-color: #263c97;
  color: white;
  font-size: 14px;
  font-weight: 400;

  a {
    color: white;
  }

  &__inner {
    padding: 0 10px;

    hr {
      height: 1px;
      margin: 0;
      border: 0;
      background: rgb(131, 131, 133);
    }
  }

  &__top {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }

  &__top-left {
    display: flex;
    gap: 20px;

  }

  &__top-right {
    ul {
      list-style-type: none;

      li {
        border-left: solid 1px rgb(131, 131, 133);
        display: inline;
        margin-left: 20px;
        padding-left: 20px;

      }

      li:nth-child(1) {
        border-left: 0;
      }
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  &__icons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;
  }
}

.logo-block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  font-size: 36px;
  font-weight: bold;
  height: 70px;
  width: 70px;
}

.search {
  display: flex;
  align-items: center;
  height: 50px;

  input {
    width: 550px;
    background: white;
    outline: none;
    border: 2px solid white;
    padding: 1em;
    height: 100%;
    border-radius: 5px 0 0 5px;
  }

  input:focus {
    outline: none;
    border: 2px solid #fcbe00;
  }

  &_btn {
    background-color: #fcbe00;
    cursor: pointer;
    color: black;
    border-left: 0;
    border-radius: 0 5px 5px 0;
    height: 100%;
    padding: 0 10px;
    align-items: center;
    display: flex;
  }
}