.nowrap {
  overflow: hidden;
}

.info {
  padding: 30px 0;
  font-size: 22px;
  line-height: 32px;
  color: black;
}

h3,
h1 {
  padding: 30px 0;
}


a{
    color: blue;
}

.pay{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__icon{
    height: 330px;
    width: 400px;
  }

  &__link{
    padding: 30px 0;
    color: blue;
    font-weight: 700;
    font-size: 22px;
    cursor: pointer;

  }
}