.category {
  margin: 20px 0;
  &__item {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    display: flex;
    gap: 20px;
  }

  &__img {
    width: 200px;
  }

  &__edit {
    display: flex;
    align-self: flex-start;
    gap: 10px;
  }
  @media only screen and (max-width: 600px) {
    &__edit {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .category__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .category__img{
    height: 200px;
    object-fit: contain;
  }
  .category__edit{
    width: 100%;
  }
}
