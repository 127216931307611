@use '../utils' as *;

/*-----------------------------------------------------------------------------------

    Theme Name: Duka Market - Clean, Minimal E-commerce HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Duka Market - Clean, Minimal E-commerce HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. theme defult  CSS
    02. background CSS
    03. spacing CSS
    04. buttons CSS
    05. carousel CSS
    06. modal CSS
    07. section-title CSS
    08. breadcrumb CSS
    09. animation CSS
    10. header CSS
    11. slider CSS
    12. features CSS
    13. product CSS
	14. about CSS
    15. blog CSS
    16. cart CSS
    17. meanmenu CSS
    18. footer CSS


**********************************************/




/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
	font-family: $rubik;
	font-size: 16px;
	font-weight: normal;
	color: $grey;
	line-height: 26px;
}

a{
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $rubik;
	color: $black-3;
	margin-top: 0px;
	font-weight: 500;
	line-height: 1.2;
	@include transition(.3s);
}

h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
h5 {
	font-size: 16px;
}
h6 {
	font-size: 14px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none;
}
p {
	font-family: $grey;
	font-size: 14px;
	font-weight: normal;
	color: $grey;
	margin-bottom: 15px;
	line-height: 24px;
}


a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	color: inherit;
	text-decoration: none;
}

a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}

button:hover{
	cursor: pointer;
}

button:focus{
    outline: 0; 
    border:0
}

.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

input{
	outline: none;
}

*::-moz-selection {
	background: $black;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $black;
	color:$white;
	text-shadow: none;
}
::selection {
	background: $black;
	color: $white;
	text-shadow: none;
}


*::-moz-placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img{
	& img{
		width: 100%;
	}
}

.m-img{
	& img{
		max-width: 100%;
	}
}

.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left {
	float: left
}
.f-right {
	float: right
}

.z-index-1{
	z-index: 1;
}

.z-index-11{
	z-index: 11;
}

.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}

.include-bg{
	@include background();
}
.b-radius{
	border-radius: 2px;
}
.b-radius-2{
	border-radius: 20px;
}

.z-tooltip{
	position: absolute;
	top: -22px;
	left: 50%;
	@include transform(translateX(-50%));
	font-size: 13px;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	text-align: center;
	padding: 0 8px;
	background: $black-2;
	color: $white;
	z-index: 1;
	width: 80px;
	@include border-radius(2px);
	visibility: hidden;
	opacity: 0;
	&::after{
		position: absolute;
		content: '';
		left: 50%;
		@include transform(translateX(-50%));
		bottom: -4px;
		border-top:4px solid $black-2;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
	}
}

//coustom container
@media (min-width:1400px) {
	.custom-conatiner{
		max-width: 1730px
	}
}


/*----------------------------------------
    Body Overlay 
-----------------------------------------*/

.body-overlay{
    background-color: rgba($color: #000000, $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(.3s);

	&:hover{
		cursor: pointer;
	}
}

.body-overlay.opened{
	opacity: 1;
    visibility: visible;
}

/*----------------------------------------
    Section Bg
-----------------------------------------*/


/*----------------------------------------
    Progress Wrap
-----------------------------------------*/

.progress-wrap{
	@media #{$xs}{
		right: 15px;
		bottom: 15px;
	}
}


/*----------------------------------------
    btn css
-----------------------------------------*/