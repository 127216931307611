.user {
  margin: 20px 0;
  &__item {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    display: flex;
    justify-content: space-between;
  }
  &__btn{
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  &__block{
    color: red;
  }

  &__img {
    width: 200px;
  }

  &__edit {
    display: flex;
    align-self: flex-start;
    gap: 10px;
  }
  @media only screen and (max-width: 600px) {
    &__edit {
        display: flex;
        flex-direction: column;
    }
  }
  &__btn{
    display: flex;
    justify-content: space-between;
  }
  &__title{
    cursor: pointer;
    
  }
}

@media only screen and (max-width: 750px) {
  .user__item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
  }
}
