.catalog {
  padding: 30px 0;

  &__img {
    height: 400px;
    object-fit: cover
  }
}

.banners_items {
  padding: 30px 0;
}

.banners-btn {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 750px) {
  .banners__items {
    display: flex;
    flex-direction: column;

    ;
  }
}

.dol {
  display: flex;
}