.sort {
  position: relative;

  &__label {
    display: flex;
    align-items: center;
    color: white;

    svg {
      margin-left: 8px;
      color: white;
    }

    b {
      margin-right: 4px;
      font-size: 14px;
      font-weight: 400;
    }

    span {
      color: white;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  &__popup {
    position: absolute;
    right: -30px;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    color: black;
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
    z-index: 100;
    ul {
      overflow: hidden;
      padding-left: 0;
      li {
        padding: 5px 15px;
        cursor: pointer;

        &.active,
        &:hover {
          background: rgba(254, 95, 30, 0.05);
        }

        &.active {
          font-weight: bold;
          color: orange;
        }
      }
    }
  }
}