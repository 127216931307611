.subcategory {
  padding: 30px 0;
}

.backlink {
  display: flex;
  justify-content: flex-end;
}

.empty {
  width: 100%;
  text-align: center;
  h1 {
    padding: 120px 0;
  }
}
