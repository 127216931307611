.push {
    &-btns {
        display: flex;
        justify-content: space-between;
    }
    &-send {
        &-btns {
            display: flex;
            margin-bottom: 30px;
            button {
                &:not(:last-child) {
                    margin-right: 12px;
                }
            }
        }
    }
    &-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    &-item {
        position: relative;
        display: flex;
        padding: 16px;
        box-shadow: 0 4.465116500854492px 12.279070854187012px 0 rgba(140, 140, 140, 0.25);
        border-radius: 12px;
        &__check {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            margin-right: 16px;
            input {
                width: 20px;
                height: 20px;
            }
        }
    }
    &-table {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        &__lang {
            position: absolute;
            top: 24px;
            left: 0;
        }
        &__head {
            height: 24px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        &__row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            &:not(:last-child) {
                padding-bottom: 12px;
                margin-bottom: 12px;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
            }
        }
        &__item {
            padding: 8px;
            &:not(:last-child) {
                border-right: 1px solid rgba(0, 0, 0, .1);
            }
        }
        &__img {
            width: 130px;
        }
    }
}

.action-btns {
    position: absolute;
    top: -18px;
    right: 1%;
    display: flex;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50px;
        svg {
            width: 22px;
            height: 22px;
        }
    }
    &__edit {
        margin-right: 12px;
    }
}

@media only screen and (max-width: 750px) {
    .category__item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}

