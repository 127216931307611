	
    // after before
    %theme-afbf{
        position: absolute;
        content: '';
    }


    @mixin bg-color($color, $opacity) {
        background: rgba($color, $opacity);
    }
    

    //backgroud 
    @mixin background {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
      
    //transition
    @mixin transition($time) {
        -webkit-transition: all $time ease-out 0s;
        -moz-transition: all $time ease-out 0s;
        -ms-transition: all $time ease-out 0s;
        -o-transition: all $time ease-out 0s;
        transition: all $time ease-out 0s;
    }
    
    // transform
    @mixin transform($transforms) {
        -webkit-transform: $transforms;
        -moz-transform: $transforms;
        -ms-transform: $transforms;
        transform: $transforms;
    }
    
    //border
    @mixin border-radius($man) {
    -webkit-border-radius: $man;
    -moz-border-radius: $man;
    border-radius: $man;
    }
    
    // sentence case
    @mixin sentence-case() {
        text-transform: lowercase;
        &:first-letter {
            text-transform: uppercase;
        }
    }

    // Flexbox display
    @mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    }
    
    // Box shadows
    @mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
        -moz-box-shadow: $shadow;       
            box-shadow: $shadow;
    }
    
    